<template>
  <v-main app>
    <!-- in ios add pt-8 -->
    <v-container :class="$ios?'pt-8':''">
      <router-view :key="$route.path" />
    </v-container>
  </v-main>
</template>

<script>
  export default {
    name: 'DefaultView',
  }
</script>