<template>
	<!-- eslint-disable -->
	<!-- in ios add ios in class -->
	<v-app-bar :class="$pad? 'ios' : $platform == 'ios' ? 'small_padding' : '' " id="default-app-bar" app fixed>
		<!-- in ios add style="margin-top:75px;" -->
		<v-img 
			src="../../../assets/AG-Avatar.svg"  
			contain
			:style="
			$pad? 'margin-top:75px;': $ios? 'margin-top:35px': ''
			" 
			max-width="40" 
		/>
		<v-spacer></v-spacer>
		<!-- in ios replace app-bar-title-android swith app-bar-title-ios   -->
		<v-toolbar-title class="primary--text app-bar-title-android"
		:class="$pad? 'app-bar-title-ios' : $ios? 'app-bar-title-ios-no-nutch': 'app-bar-title-android'"
		
		v-text="$tr('Alamal Group')" />
		<v-spacer></v-spacer>
	</v-app-bar>
</template>

<script>
	/* eslint-disable */
	import { Capacitor  } from '@capacitor/core';

	export default {
		name: "DefaultBar",
		computed: {
			ios(){
				return this.$ios
			}
		}
	};
</script>
<style>
	.app-bar-title-ios {
		padding-top: 75px !important;
		font-family: "Poppins-Bold";
		font-size: 26px;
		padding-right: 30px;
		font-weight: 700;
	}

	.app-bar-title-ios-no-nutch {
		padding-top: 35px !important;
		font-family: "Poppins-Bold";
		font-size: 26px;
		padding-right: 30px;
		font-weight: 700;
	}
	.app-bar-title-android {
		font-family: "Poppins-Bold";
		font-size: 26px;
		padding-right: 30px;
		font-weight: 700;
	}
	.normal-text {
		font-family: "Poppins";
		font-weight: medium;
		font-size: 24px;
	}
	.ios {
		height: 90px !important;
	}
	.small_padding {
		height: 70px !important;
	}
</style>
